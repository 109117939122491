import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { HTMLParser, ScreenResizer, SocialShare } from '@/components';
import { Container } from '@/layouts/Container';

type PostContentProps = {
  html: string;
  isSuggested?: boolean;
};

export const PostContent = ({ html }: PostContentProps) => {
  return (
    <Container hiddenOverflow>
      <div className="relative flex w-full flex-none sm:justify-center sm:py-container">
        <ScreenResizer displayOnly="desktop">
          <div className="sticky top-[150px] mr-10">
            <SocialShare />
          </div>
        </ScreenResizer>
        <div className="text-xl !leading-normal text-primary-700 sm:max-w-5xl">
          <HTMLParser>{html}</HTMLParser>
        </div>
      </div>
    </Container>
  );
};
