import React, { type ReactNode } from 'react';

import { ScreenResizer, SocialShare } from '@/components';
import { Container } from '@/layouts/Container';

type PostContentProps = {
  children: ReactNode;
};

export const PostContentPrismParser = ({ children }: PostContentProps) => {
  return (
    <Container hiddenOverflow>
      <div className="relative flex w-full flex-none sm:justify-center sm:py-container">
        <ScreenResizer displayOnly="desktop">
          <div className="sticky top-[150px] lg:mr-10">
            <SocialShare />
          </div>
        </ScreenResizer>
        <div className="w-full text-xl !leading-normal text-primary-700 xl:max-w-5xl">
          {children}
        </div>
      </div>
    </Container>
  );
};
